@property --a {
	syntax: "<angle>";
	inherits: false;
	initial-value: 10deg;
}

@property --color-from {
	syntax: "<color>";
	inherits: false;
	initial-value: #ffffff;
}

@property --color-to {
	syntax: "<color>";
	inherits: false;
	initial-value: #ffffff;
}
// .box {
// 	--a: 10deg; /*  needed for firefox to have a valid output */
// 	cursor: pointer;
// 	width: 250px;
// 	height: 200px;
// 	margin: 15px;
// 	display: inline-block;
// 	transition: --a 0.5s;
// 	background: conic-gradient(from var(--a), red var(--a), blue);
// }
// .box:hover {
// 	--a: 180deg;
// }
