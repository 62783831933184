@property --num {
	syntax: "<integer>";
	initial-value: 0;
	inherits: false;
}

// .animateOnScrollTriggered .CountryMap {
// // 	animation: counter 3s alternate forwards ease-in-out;
// // }

.CountryMap {
	& {
		// --num: 0;
		min-width: 300px;
		font: 800 40px system-ui;
		padding: 2rem 0;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		top: 0;
		position: relative;
		top: -20px;
	}
	& a {
		position: relative;
	}

	&:has(g:hover, .MapText:hover) {
		// & g {
		stroke-dasharray: 690% 300;
		stroke-dashoffset: 365;
		// }
	}

	& svg {
		width: 100%;
		flex-grow: 1;
		position: relative;
		stroke: #848484;
		stroke-width: 6px;
		stroke-linejoin: round;
		transition: stroke 3s ease-in-out;
	}
	& .MapText {
		position: absolute;
		bottom: 40px;
		transition: top 5s ease-in-out;
		padding-top: 20px;
		background: conic-gradient(at 8% 7%, #d25502eb 10%, #7a3900 141%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}


	& g {
		filter: drop-shadow(0px 0px 0px #00000000);
		stroke-dasharray: 178% 400;
		stroke-dashoffset: 0;
		fill: transparent;

		transition: filter 3s ease-in-out, stroke-dashoffset 5s ease-in-out,
			fill 5s ease-in-out;
	}
	&.animateOnScrollTriggered {
		&:has(#Jordan) g {
			stroke-dasharray: 195% 396;
			stroke-dashoffset: 285;
			stroke-linecap: round;
		}

		&:has(#Iraq) g {
			stroke-dasharray: 188% 300;
			stroke-dashoffset: 365;
			stroke-linecap: round;
		}
		& svg {
			// bottom: -80px;
		}

		& .MapText {
			bottom: 40px;
			animation-play-state: unset;
			counter-reset: num var(--num);

			animation: counter 5s alternate forwards ease-in-out;
			animation-play-state: running;
			color: white;
			filter: drop-shadow(0px 13px 5px #0000004d);

			& .Counter::after {
				// display: none;
				content: "+" counter(num);
				// --num: 0;
			}
		}

		& g {
			filter: drop-shadow(0px 13px 5px #0000004d);
			fill: #ffffff30;
		}
		&:hover {
			& g {
				// filter: drop-shadow(0px 13px 5px #0000004d);
				// fill: #b8000030;
				// stroke-dasharray: 690% 300 !important;
				transition-duration: 2.5s;
				stroke-dashoffset: 2000 !important;
			}

			& svg {
				stroke: #e84e0eeb;
			}

			& .MapText {
				background: "conic-gradient(at 8% 7%, rgb(210 85 2 / 92%) 10%, rgb(122 57 0) 141%)";
			}
		}
		// animation: counter 3s alternate forwards ease-in-out;
	}

	@keyframes counter {
		from {
			--num: 0;
			// fill: transparent;
			// color: transparent;
		}
		to {
			--num: var(--upto);
			// fill: white;
			// color: black;
		}
	}
}
