.DNA {
	--time: 60s;
	--unit: calc(40px + 25px);
	--rotation: -30deg;

	& {
		// margin: 0 150px;
		width: 100%;
		min-width: 600px;
		height: 600px;
		// min-width: 600px;

		position: relative;
		// background-color: white;
		flex-grow: 1;
		// overflow-y: hidden;
		// overflow-x: hidden;
	}
	&.onScreen {
		& .node {
			animation-play-state: running !important;
		}
	}

	main {
		width: 0px;
		height: 600px;
		position: relative;
		left: 50%;
		top: 50%;

		transform-origin: 0 0;
		transform: rotate(calc(-1 * var(--rotation)));
	}

	section {
		position: absolute;
		perspective-origin: 0 0;
		perspective: calc(var(--unit) * 20);
		--n: 0;

		top: calc((var(--n) - 4.5) * var(--unit) * 1.4);

		&:nth-last-child(1) {
			top: calc((var(--n) - 4.5) * var(--unit) * 1.35);

			.node {
				width: calc(0.8 * var(--unit)) !important;
				height: calc(0.8 * var(--unit)) !important;
			}
		}
		&:nth-last-child(2) {
			.node {
				width: calc(1 * var(--unit)) !important;
				height: calc(1 * var(--unit)) !important;
			}
		}
		&:nth-of-type(1) {
			top: calc((var(--n) - 4.5) * var(--unit) * 1.3);

			.node {
				width: calc(0.8 * var(--unit)) !important;
				height: calc(0.8 * var(--unit)) !important;
			}
			--n: 0;
			z-index: 18;
		}
		&:nth-of-type(2) {
			top: calc((var(--n) - 4.5) * var(--unit) * 1.3);

			.node {
				width: calc(1 * var(--unit)) !important;
				height: calc(1 * var(--unit)) !important;
			}
			--n: 1;
			z-index: 18;
		}
		&:nth-of-type(3) {
			--n: 2;
			z-index: 17;
		}
		&:nth-of-type(4) {
			--n: 3;
			z-index: 16;
		}
		&:nth-of-type(5) {
			--n: 4;
			z-index: 15;
		}
		&:nth-of-type(6) {
			--n: 5;
			z-index: 14;
		}
		&:nth-of-type(7) {
			--n: 6;
			z-index: 13;
		}
		&:nth-of-type(8) {
			--n: 7;
			z-index: 12;
		}
		&:nth-of-type(9) {
			--n: 8;
			z-index: 11;
		}
		&:nth-of-type(10) {
			--n: 9;
			z-index: 10;
		}
		&:nth-of-type(11) {
			--n: 10;
			z-index: 9;
		}
		&:nth-of-type(12) {
			--n: 11;
			z-index: 8;
		}
		&:nth-of-type(13) {
			--n: 12;
			z-index: 7;
		}
		&:nth-of-type(14) {
			--n: 13;
			z-index: 6;
		}
		&:nth-of-type(15) {
			--n: 14;
			z-index: 5;
		}
		&:nth-of-type(16) {
			--n: 15;
			z-index: 4;
		}
		&:nth-of-type(17) {
			--n: 16;
			z-index: 3;
		}
		&:nth-of-type(18) {
			--n: 17;
			z-index: 2;
		}
		&:nth-of-type(19) {
			--n: 18;
			z-index: 1;
		}
		&:nth-of-type(20) {
			--n: 19;
			z-index: 0;
		}

		&:nth-of-type(2n) :nth-of-type(1),
		&:nth-of-type(2n + 1) :nth-of-type(2) {
			// --startColor: #e84e0e;
			// --midColor: #e84e0e;
			// --endColor: #e84e0e;
			--startColor: rgb(255, 255, 255);
			--midColor: rgb(212, 212, 212);
			--endColor: rgb(193, 193, 193);
		}

		&:nth-of-type(2n) :nth-of-type(2),
		&:nth-of-type(2n + 1) :nth-of-type(1) {
			--startColor: rgb(255, 255, 255);
			--midColor: rgb(212, 212, 212);
			--endColor: rgb(193, 193, 193);
			// --startColor: #ffffff;
			// --midColor: #d2d2d2;
			// --endColor: #919090;
		}

		.node {
			position: absolute;
			width: calc(1.3 * var(--unit));
			height: calc(1.3 * var(--unit));
			left: calc(var(--unit) * -0.5);
			top: calc(var(--unit) * -0.5);
			border-radius: 50%;
			animation: spin var(--time) linear infinite;
			animation-play-state: paused;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.36);
			text-decoration: none;
			color: white;
			transition: all 0.2s ease-in-out;
			& img {
				border-radius: 50%;

				overflow: hidden;
				display: block;
				object-fit: cover;
				overflow: hidden;
				width: 100%;
				aspect-ratio: 1/1;
				opacity: 0.7;

				transition: opacity 0.2s ease-in-out;
			}

			&:has(img):hover {
				width: calc(1.5 * var(--unit)) !important;
				height: calc(1.5 * var(--unit)) !important;
				left: calc(var(--unit) - calc(1.5 * var(--unit)));
				top: calc(var(--unit) - calc(1.5 * var(--unit)));

				& img {
					opacity: 1;
				}
				&::after,
				&::before {
					display: flex;
					justify-content: center;
				}
			}

			&::after,
			&::before {
				// backdrop-filter: blur(1px);
				background-color: #7f7f7f;
			}

			&::after {
				position: relative;
				left: calc(50% - 75px);
				bottom: -10px;
				padding: 5px 10px;
				content: attr(data-name);
				display: none;
				width: 150px;
				height: 50px;
				align-items: center;
				border-radius: 5px;
				z-index: 1000;
			}

			&::before {
				position: absolute;
				bottom: -50%;
				left: calc(50% - 10px);
				// background-color: aqua;
				content: "";
				height: 20px;
				width: 20px;
				transform: rotateZ(45deg);
				display: none;
			}

			// &:nth-of-type(1) &:nth-of-type(3):

			&:nth-of-type(1) {
				animation-delay: calc(
					var(--n) * -1 *
						calc(var(--time) / 10 + calc(var(--time) / 2))
				);

				&::after {
					counter-reset: n calc(var(--n) * 2 + 2);
				}
			}

			&:nth-of-type(2) {
				animation-delay: calc(
					(
							var(--n) * -1 *
								calc(var(--time) / 10 + calc(var(--time) / 2))
						) - calc(var(--time) / 2)
				);

				&::after {
					counter-reset: n calc(var(--n) * 2 + 1);
				}
			}
		}
	}

	// section {
	// 	position: absolute;
	// 	perspective-origin: 0 0;
	// 	perspective: calc(var(--unit) * 20);
	// 	--n: 0;
	// 	top: calc((var(--n) - 4.5) * var(--unit) * 1.4);
	// }

	// section > div.edge {
	// 	width: 200px;
	// 	height: 60px;
	// 	border-radius: 0%;
	// 	--startColor: hsla(10, 100%, 55%, 1);
	// 	--midColor: hsla(21, 100%, 55%, 1);
	// 	--endColor: hsla(55, 100%, 55%, 1);
	// }

	// section > div.edge {
	// 	position: absolute;
	// 	width: 160px;
	// 	height: 10px;
	// 	left: -80px;
	// 	top: -5px;
	// 	background-color: white;
	// 	transform-origin: center;
	// 	animation: rot 4s ease-in-out infinite;
	// }

	// section > div.edge:nth-of-type(1) {
	// 	--startColor: hsla(222, 64%, 55%, 1);
	// 	--midColor: hsla(204, 64%, 55%, 1);
	// 	--endColor: hsla(150, 64%, 55%, 1);
	// 	animation-delay: calc(var(--n) * -0.6s);
	// }
	// section > div.edge:nth-of-type(2) {
	// 	--startColor: hsla(10, 100%, 55%, 1);
	// 	--midColor: hsla(21, 100%, 55%, 1);
	// 	--endColor: hsla(55, 100%, 55%, 1);
	// 	animation-delay: calc(var(--n) * -0.6s - 2s);
	// }

	// section > div.node {
	// 	position: absolute;
	// 	width: var(--unit);
	// 	height: var(--unit);
	// 	left: calc(var(--unit) * -0.5);
	// 	top: calc(var(--unit) * -0.5);
	// 	border-radius: 50%;
	// 	animation: spin 4s ease-in-out infinite;
	// }

	// section > div.node:nth-of-type(1) {
	// 	--startColor: hsla(222, 64%, 55%, 1);
	// 	--midColor: hsla(204, 64%, 55%, 1);
	// 	--endColor: hsla(150, 64%, 55%, 1);
	// 	animation-delay: calc(var(--n) * -0.6s);
	// }
	// section > div.node:nth-of-type(3) {
	// 	--startColor: hsla(10, 100%, 55%, 1);
	// 	--midColor: hsla(21, 100%, 55%, 1);
	// 	--endColor: hsla(55, 100%, 55%, 1);
	// 	animation-delay: calc(var(--n) * -0.6s - 2s);
	// }

	// &:hover section > div.node {
	// 	animation: spin 6s linear infinite;
	// }

	// &:hover section > div.node:nth-of-type(2) {
	// 	animation-delay: calc(var(--n) * -0.8s - 3s);
	// }

	// &:hover section > div.node:nth-of-type(1) {
	// 	animation-delay: calc(var(--n) * -0.8s);
	// }

	// section:nth-of-type(2) {
	// 	--n: 1;
	// }
	// section:nth-of-type(3) {
	// 	--n: 2;
	// }
	// section:nth-of-type(4) {
	// 	--n: 3;
	// }
	// section:nth-of-type(5) {
	// 	--n: 4;
	// }
	// section:nth-of-type(6) {
	// 	--n: 5;
	// }
	// section:nth-of-type(7) {
	// 	--n: 6;
	// }
	// section:nth-of-type(8) {
	// 	--n: 7;
	// }
	// section:nth-of-type(9) {
	// 	--n: 8;
	// }
	// section:nth-of-type(10) {
	// 	--n: 9;
	// }
	// section:nth-of-type(11) {
	// 	--n: 10;
	// }
	// section:nth-of-type(12) {
	// 	--n: 11;
	// }
	// section:nth-of-type(13) {
	// 	--n: 12;
	// }
	// section:nth-of-type(14) {
	// 	--n: 13;
	// }
	// section:nth-of-type(15) {
	// 	--n: 14;
	// }
	// section:nth-of-type(16) {
	// 	--n: 15;
	// }
	// section:nth-of-type(17) {
	// 	--n: 16;
	// }
	// section:nth-of-type(18) {
	// 	--n: 17;
	// }
	// section:nth-of-type(19) {
	// 	--n: 18;
	// }
	// section:nth-of-type(20) {
	// 	--n: 19;
	// }

	@keyframes spin {
		0% {
			transform: rotateY(0) translate3d(calc(var(--unit) * 2.5), 0, 0)
				rotateY(0) rotateZ(var(--rotation));
			z-index: 0;
			background-color: var(--midColor);
		}
		25% {
			z-index: 20;
			background-color: var(--startColor);
		}
		75% {
			z-index: -20;
			background-color: var(--endColor);
		}
		100% {
			transform: rotateY(-360deg)
				translate3d(calc(var(--unit) * 2.5), 0, 0) rotateY(360deg)
				rotateZ(var(--rotation));
			z-index: 0;
			background-color: var(--midColor);
		}
	}

	// @keyframes rotation {
	// 	0% {
	// 		main {
	// 			transform: rotate(calc(var(--rotation)));
	// 		}
	// 		// --rotate: 30deg;
	// 	}
	// 	50% {
	// 		main {
	// 			transform: rotate(calc(-1 * var(--rotation)));
	// 		}
	// 	}

	// 	100% {
	// 		main {
	// 			transform: rotate(calc(var(--rotation)));
	// 		}
	// 	}
	// }
	// @keyframes rot {
	// 	0% {
	// 		transform: rotateY(0deg);
	// 		z-index: 0;
	// 		// background: var(--midColor);
	// 		background: linear-gradient(
	// 			45deg,
	// 			var(--startColor),
	// 			var(--midColor)
	// 		);
	// 	}
	// 	25% {
	// 		z-index: 20;
	// 		// background-color: var(--startColor);
	// 		background: linear-gradient(
	// 			45deg,
	// 			var(--startColor),
	// 			var(--midColor)
	// 		);
	// 	}
	// 	50% {
	// 		transform: rotateY(180deg);
	// 	}
	// 	75% {
	// 		z-index: -20;
	// 		// background-color: var(--endColor);
	// 		background: linear-gradient(
	// 			45deg,
	// 			var(--startColor),
	// 			var(--midColor)
	// 		);
	// 	}
	// 	100% {
	// 		transform: rotateY(0deg);
	// 		z-index: 0;
	// 		// background-color: var(--midColor);
	// 		background: linear-gradient(
	// 			45deg,
	// 			var(--startColor),
	// 			var(--midColor)
	// 		);
	// 	}
	// }
}
